
// const dev = {
//   API_ENDPOINT_URL: 'https://falsom.detailslocal.com/api',
//   SITE_URL: 'https://falsom.detailslocal.com',
//   WS_SOCKET_URL: 'wss://detailslocal.com:3000',
//   WS_SOCKET_PORT: 3200,
// };

const dev = {
  API_ENDPOINT_URL: 'https://api.osm.detailslocal.com/api',
	SITE_URL: 'https://api.osm.detailslocal.com',
	WS_SOCKET_URL: 'wss://detailslocal.com:3200',
	WS_SOCKET_PORT: 3200,
};

const prod = {
  API_ENDPOINT_URL: 'https://api.osm.detailslocal.com/api',
	SITE_URL: 'https://api.osm.detailslocal.com',
	WS_SOCKET_URL: 'wss://detailslocal.com:3200',
	WS_SOCKET_PORT: 3200,
};

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		default:
			break;
	}
}

export const env = getEnv()
